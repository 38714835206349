let es = {
    translation: {
      'We will be back very soon': 'Regresaremos muy pronto',
      'we are under maintenance': 'estamos en mantenimiento',
      'Check this': 'Mira este',
      'almost': 'ya casi',
      'days': 'dias',
      'day': 'dia',
      'hours': 'horas',
      'hour': 'hora',
      'less than 1 hour': 'menos de una hora',
      'and': 'y',
      'Maintenance': 'Mantenimiento',
    }
}

export default es;
