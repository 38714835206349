import {Component} from 'react'
import RoutingService from "./RoutingService";

class SecurityService extends Component {
    static acl = {
        home: { path: '/', auth: false, namespace: 'app' }
    };

    static protectByAcl() {
        let url = null;
        let acl = SecurityService.acl;
        let id_value = null;

        for (let key in acl) {
            let path = acl[key].path

            if (path.indexOf(':id') >= 0) {
                //Tiene parámetros...
                let path_pieces = path.split('/');
                let index_of_id = -1;
                path_pieces.some((x) => { index_of_id++; return (x === ':id') });

                path_pieces = window.location.pathname.toLowerCase().split('/');
                id_value = path_pieces[index_of_id];
                path = path.replace(':id', id_value);

                if (RoutingService.urlContainsExactly(path)) {
                    url = acl[key];
                    break;
                }                
            }
            else {
                if (RoutingService.urlContainsExactly(path)) {
                    url = acl[key];
                    break;
                } 
            }
        }

        if (!url) {
            RoutingService.redirectTo(acl.home.path);
        }
    }
}

export default SecurityService;
