import MyReactComponent from "../MyReactComponent";

class HtmlService extends MyReactComponent {
    static addLayoutScript(src) {
        let script = document.createElement("script");
        script.src = src;
        script.async = false;

        document.body.appendChild(script);
    }

    static showLoadingComponent(show_logo = false) {
        document.getElementById('loader').style.display = 'block';
        if (show_logo) document.getElementById('loader-logo').style.display = 'block';
    }

    static showContent() {
        document.getElementById('dt-root').style.display = 'block';
    }

    static resetContentScroll() {
        document.getElementById('root').scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    }

    static scrollToTarget(id, top_offset=0, add_hash=true) {
        const element = document.getElementById(id);
        if (!element) return;

        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - top_offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });

        if (add_hash) window.history.pushState({}, '', '#' + id);
    }

    static fullScreenMode() {
        let html = document.documentElement;

        if (html.requestFullscreen) html.requestFullscreen();
        else if (html.webkitRequestFullscreen) html.webkitRequestFullscreen();
        else if (html.msRequestFullscreen) html.msRequestFullscreen();
    }

    static exitfullScreenMode() {
        if (document.exitFullscreen) document.exitFullscreen();
        else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
        else if (document.msExitFullscreen) document.msExitFullscreen();
    }

    static highLightText(text, filter_text) {
        if (!text) return text;
        if (!filter_text) return text;

        let text_tokens = text.split(' ');
        let filter_tokens = filter_text.split(' ');

        filter_tokens.forEach((filter_token) => {
            if (filter_token) {
                let reg = new RegExp(filter_token, 'gi');
                let text_token_index = 0;

                text_tokens.forEach((text_token) => {
                    if (text_token.indexOf('<span class="highliht">') < 0) {
                        text_tokens[text_token_index] = text_tokens[text_token_index].replace(reg, function(str) {
                            return '<span class="highliht">' + str + '</span>'
                        });
                    }

                    text_token_index++;
                });
            }
        });

        // text_tokens = text_tokens.map((token) => {
        //     if (token.indexOf('<span class="highliht">') < 0) 
        //         return  + token + '</span>';

        //     return token;
        // });

        text = '<span class="hidden">' + text_tokens.join(' ') + '</span>';
        return text;

        // let sentences = text.split('.').map((sentence) => {
        //     if (sentence.indexOf('<span class="highliht">') < 0) 
        //         return '<span class="hidden">' + sentence + '</span>';

        //     return sentence;
        // });

        // return sentences.join(' ');
    }

    static isOnline() {
        return navigator.onLine;
    }
}

export default HtmlService;
