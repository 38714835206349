import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import es from './es'
import pt from './pt'
import {browser_locale} from './parameters'

i18n
    .use(initReactI18next)
    .init({
        lng: browser_locale,
        fallbackLng: "en",
        keySeparator: false,
        interpolation: { escapeValue: false },

        resources: {
            es: es,
            pt: pt,
        },
    });
