import React from "react";
import MyReactComponent from "../MyReactComponent";
import { withTranslation } from 'react-i18next'
import {withRouter} from "react-router-dom";

import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

import { browser_locale, project, phone, email, primary_color, article_name, article_url, begin_date_time, end_date_time, keywords, isEco,isProd, env } from '../parameters';

import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from "dayjs"
import HtmlService from '../Services/HtmlService';
import DataService from '../Services/DataService';
import { Offline } from 'react-detect-offline';
import { EnvFeedback } from './components';

class Layout extends MyReactComponent {
    state = {
        keywords: this.getKeywords(),
        progress: 0,
        left_text: '...',
        is_online: HtmlService.isOnline(),
    }

    getKeywords() {
        if (keywords[browser_locale]) {
            return keywords[browser_locale].split('||');
        }

        return [project.toUpperCase()];
    }

    globalStyles() {
        let global_styles = `
            .content a {  
                color: #${primary_color} !important;
            }

            .MuiLinearProgress-barColorPrimary {
                background-color: #${primary_color} !important; 
            }
        `

        return global_styles;
    } 

    calcLeftTime(begin_at, begin_at_id, end_at, end_at_id) {
        let now = dayjs();
        let now_id = now.unix();

        let progress = ((now_id-begin_at_id)/(end_at_id-begin_at_id))*100;
        progress = parseInt(progress.toFixed(0));

        if (progress >= 100) {
            this.setState({
                progress: 100,
                left_text: this.props.t('almost')
            });
        }
        else {
            let days_diff = end_at.diff(now, "days");

            let hours_diff = end_at.diff(now, "hours");
            const days = Math.floor(hours_diff / 24);
            hours_diff = hours_diff - (days * 24);            

            let text = '';

            if (days_diff > 1) text = days_diff + ' ' + this.props.t('days');
            else if (days_diff === 1) text = '1 ' + this.props.t('day');

            if (days_diff > 0) text = text + ' ' + this.props.t('and') + ' ';

            if (hours_diff > 1) text = text + hours_diff + ' ' + this.props.t('hours');
            else if (hours_diff === 1) text = text + '1 ' + this.props.t('hour');
            else if (hours_diff === 0 && days_diff === 0) text = text + this.props.t('less than 1 hour');

            if (browser_locale === 'en') text = text + ' left';
            if (browser_locale === 'es') text = text + ' para concluir';
            if (browser_locale === 'pt') text = text + ' para ir';

            this.setState({
                progress: progress,
                left_text: text
            }); 
        }
    }

    componentDidMount() {
        dayjs.extend(customParseFormat)

        let begin_at = dayjs(begin_date_time, "YYYY-MM-DD hh:mm a");
        let begin_at_id = begin_at.unix();

        let end_at = dayjs(end_date_time, "YYYY-MM-DD hh:mm a");
        let end_at_id = end_at.unix();

        let tmp_timer = setInterval(() => {
            this.calcLeftTime(begin_at, begin_at_id, end_at, end_at_id);
            clearTimeout(tmp_timer);
        }, 250);

        let timer = setInterval(() => {
            this.calcLeftTime(begin_at, begin_at_id, end_at, end_at_id);

            if (this.state.progress === 100) {
                clearTimeout(timer);
            }

        }, 10000);
    } 

    render() {
        const {t} = this.props;

        document.title = DataService.capitalize(project.toLowerCase()) + ' - ' + t('Maintenance');

        let favicon = document.getElementById("favicon");
        if (favicon && project) favicon.href = "/projects/" + project.toLowerCase() + "/favicon.png";

        const keywords_list = this.state.keywords.map((x, index) => {
            return(
                <li key={index}>{x}</li>
            )
        });

        return (
            <React.Fragment>
                <CssBaseline />

                <style>
                    {
                        this.globalStyles()
                    }
                </style>
                <Offline polling={false} onChange={(online) => {
              this.setState({is_online: online});
            }}/>

            <div id="offline" className={this.state.is_online ? 'online' : 'offline'} >{!this.state.is_online && t('Offline')}</div>
                {!isProd && (
                    <EnvFeedback nodeEnv={DataService.capitalize(env)} />
                )}
                <Grid container spacing={3} className="header">
                    <Grid container item sm={12} className='header-items' >
                        <ul>
                            {
                                phone &&
                                <li><a href={"tel:" + phone}>{phone}</a></li>
                            }

                            {
                                email &&
                                <li><a href={"mailto:" + email}>{email}</a></li>
                            }
                        </ul>
                        {isEco && (
                          <span className="material-symbols-outlined green">
                            temp_preferences_eco
                          </span>
                        )}
                    </Grid>
                </Grid>

                <Grid container spacing={3} className="content">
                    <Grid container item sm={12}>
                        <div>
                            <img className={"logo-" + project.toLowerCase()} alt={project} src={require('./projects/' + project.toLowerCase() + '/logo.svg')} />

                            <h1>
                                {t('We will be back very soon')},<br/>
                                {t('we are under maintenance')}.
                            </h1>

                            <p id="article">
                                {t('Check this')}&nbsp;
                                <a target="_blank" rel="noopener noreferrer" href={article_url}>{article_name[browser_locale]}</a>
                            </p>

                            <LinearProgress 
                                variant="determinate" 
                                value={this.state.progress} 
                            />

                            <p id="ellapsed-time">
                                {this.state.left_text}
                            </p>
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={3} className="footer">
                    <Grid container item sm={12}>
                        <ul>
                            { keywords_list }
                        </ul>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
	}
}

export default withTranslation()(withRouter(Layout));
