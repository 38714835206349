let pt = {
     translation: {
      'We will be back very soon': 'Estaremos de volta em breve',
      'we are under maintenance': 'estamos em manutenção',
      'Check this': 'Olha este',
      'almost': 'já quase',
      'days': 'dias',
      'day': 'dia',
      'hours': 'horas',
      'hour': 'hora',
      'less than 1 hour': 'menos de uma hora',
      'and': 'e',
      'Maintenance': 'Manutenção',
     }
}

export default pt;
