import React from 'react';
import MyReactComponent from "../MyReactComponent";
import './App.css';
import './AppResponsive.css';
import Layout from './Layout'
import {BrowserRouter, Switch, Route} from "react-router-dom";

import Appsignal from "@appsignal/javascript";
import { ErrorBoundary } from "@appsignal/react";
import {app_signal_monitoring_key} from "../parameters.js";

import SecurityService from "../Services/SecurityService";

const FallbackComponent = () => (
  <div>AppSignal: There was an error...</div>
);

class App extends MyReactComponent {
    state = {
      appsignal: new Appsignal({ 
        key: app_signal_monitoring_key
      }),
    }

    render() {
        let routes = Object.keys(SecurityService.acl).map((key) => {
            if (SecurityService.acl[key].namespace !== 'app') {
                return null;
            }

            return (
                <Route exact path={SecurityService.acl[key].path} key='route'>
                    <Layout tool={key} />
                </Route>
            )
        });

        return (
            <ErrorBoundary 
              instance={this.state.appsignal}
              tags={{ tag: "generic_frontend_web_maintenance" }}
              fallback={() => <FallbackComponent />}>

                <BrowserRouter>
                    <Switch>
                        {routes}
                    </Switch>
                </BrowserRouter>

            </ErrorBoundary>
        );
    }
}

export default App;
