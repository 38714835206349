import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import SecurityService from "./Services/SecurityService";
import { font } from "./parameters.js";
import App from "./App/App";

SecurityService.protectByAcl();
document.body.classList.add(font);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

serviceWorker.unregister();
