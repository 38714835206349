export const project = window.env.GENERIC_FRONTEND_REACTJS_MAINTENANCE_PROJECT;
export const phone = window.env.GENERIC_FRONTEND_REACTJS_MAINTENANCE_PHONE;
export const email = window.env.GENERIC_FRONTEND_REACTJS_MAINTENANCE_EMAIL;
export const primary_color = window.env.GENERIC_FRONTEND_REACTJS_MAINTENANCE_PRIMARY_COLOR;
export const font = window.env.GENERIC_FRONTEND_REACTJS_MAINTENANCE_FONT.toLowerCase();
export const isEco = window.env.GENERIC_FRONTEND_REACTJS_MAINTENANCE_ECO;
export const env = window.env.NODE_ENV || 'development';
export const isProd = env === 'production';

let check_name_en = window.env.GENERIC_FRONTEND_REACTJS_MAINTENANCE_CHECK_NAME_EN.split(',')
let check_name_es = window.env.GENERIC_FRONTEND_REACTJS_MAINTENANCE_CHECK_NAME_ES.split(',')
let check_name_pt = window.env.GENERIC_FRONTEND_REACTJS_MAINTENANCE_CHECK_NAME_PT.split(',')
let check_url = window.env.GENERIC_FRONTEND_REACTJS_MAINTENANCE_CHECK_URL.split(',')

let random_position = Math.round( Math.random() * (check_name_en.length - 1) + 1 );
random_position = random_position - 1;

export const article_name = {
  'en': check_name_en[random_position].trim(),
  'es': check_name_es[random_position].trim(),
  'pt': check_name_pt[random_position].trim(),
};

export const article_url = check_url[random_position].trim();

export const begin_date_time = window.env.GENERIC_FRONTEND_REACTJS_MAINTENANCE_BEGIN_DATE_TIME;
export const end_date_time = window.env.GENERIC_FRONTEND_REACTJS_MAINTENANCE_END_DATE_TIME;

export const keywords = {
  'en': window.env.GENERIC_FRONTEND_REACTJS_MAINTENANCE_KEYWORDS_EN,
  'es': window.env.GENERIC_FRONTEND_REACTJS_MAINTENANCE_KEYWORDS_ES,
  'pt': window.env.GENERIC_FRONTEND_REACTJS_MAINTENANCE_KEYWORDS_PT,
};

export const app_signal_monitoring_key = window.env.GENERIC_FRONTEND_REACTJS_MAINTENANCE_APPSIGNAL_MONITORING_KEY;

export const browser_locale = (navigator.language || navigator.userLanguage || "en").substring(0,2);
